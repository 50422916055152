
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { mobileRulesEl, usernameRuleEl } from "@/utils/verify";
import t from "@common/src/i18n/t";
const staff = namespace("staff");
const setting = namespace("setting");
const app = namespace("app");
@Component({})
export default class StaffEdit extends Vue {
  @staff.Action saveOpAccount;
  @setting.Action getRoleList;
  @staff.Action queryAccountDetail;
  @app.State btnLoading;
  @app.Mutation btnStartLoading;
  @app.Mutation btnStopLoading;
  get breadData() {
    return [
      { name: this.$t("v210831.user-management"), path: "/setting/user" },
      { name: this.isAdd ? this.$t("v210831.new-user") : this.$t("v210831.edit-user") }
    ];
  }
  staffFormData = {
    userName: "",
    mobile: "",
    account: "",
    email: "",
    roleCodeList: [],
    roleList: [],
    status: "1"
  };
  showEditStaff: boolean = false;
  roleList: any[] = [];
  staffFormRules = {
    userName: [{ required: true, message: t("setting.enter-employee-name"), trigger: "blur" }],
    mobile: [
      { required: true, message: t("setting.employee-cell-phone-number"), trigger: "blur" },
      { validator: this.validateMobile, trigger: "blur" }
    ],
    account: [
      { required: true, message: t("setting.enter-user-name"), trigger: "blur" },
      { validator: this.validatUsername, trigger: "blur" }
    ],
    roleCodeList: [
      {
        type: "array",
        required: true,
        message: t("setting.select-employee-roles"),
        trigger: "change"
      }
    ],

    status: [{ required: true, message: t("setting.select-whether-enable"), trigger: "change" }]
  };
  get isAdd() {
    return this.$route.name === "staffAdd";
  }
  existStaffCode: string = "";
  async queryUser() {
    if (!this.isAdd) return;
    if (this.staffFormData.mobile) {
      try {
        const staffData = await this.queryAccountDetail({
          mobile: this.staffFormData.mobile
        });
        // alert("已经存在");
        this.existStaffCode = staffData.data.userCode;
        this.showEditStaff = true;
      } catch (e) {
        console.log(e);
      }
    }
  }
  linkEditStaff() {
    if (this.existStaffCode) {
      this.$router.push(`/setting/staff-edit/${this.existStaffCode}?type=1`);
    }
  }

  created() {
    this.init();
  }
  validateMobile(rule, value, callback) {
    mobileRulesEl(value, callback);
  }
  validatUsername(rule, value, callback) {
    usernameRuleEl(value, callback);
  }
  submitForm(formName) {
    (this.$refs[formName] as any).validate(valid => {
      if (valid) {
        this.btnStartLoading();

        this.addStaff();
      } else {
        console.log("error submit!!");
        return false;
      }
    });
  }
  async init() {
    const roleListData = await this.getRoleList({
      pageNum: 1,
      pageSize: 100
    });
    if (!this.isAdd) {
      try {
        const staffData = await this.queryAccountDetail({
          userCode: this.$route.params.id
        });
        staffData.data.roleCodeList = staffData.data.roleList ? staffData.data.roleList.map(item => item.roleCode) : [];
        this.staffFormData = staffData.data;
      } catch (e) {
        console.log(e);
        // this.$message.error(e.message);
      }
    }
    this.roleList = roleListData.data.list;
  }

  async addStaff() {
    this.saveOpAccount(this.staffFormData)
      .then(data => {
        this.$message.success(this.$t("base.success") as any);
        this.$router.push(`/setting/user`);
      })
      .finally(item => {
        this.btnStopLoading();
      });
  }
}
